Array.prototype.shuffled = function() {
  return this.map(function(n){ return [Math.random(), n] })
    .sort().map(function(n){ return n[1] });
}

export default {
  
  shuffledAssets: (state) => {
    return state.feed.shuffled()
  },
 
  findAssetByID: (state) => (id) => {
		return state.feed.find(asset => asset.id === id)
  },

  filterPoints: (state) => (filters) => {
		return state.points.filter(point => { 
      return ((filters.filetype.length) ? filters.filetype.includes(point.filetype) : point) && ((filters.mood.length) ? filters.mood.includes(point.mood) : point)
    })
  }
  
}