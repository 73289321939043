<template>
  <div class="input">

    <el-input
      placeholder="Start typing the place (eg. New York)"
      ref="placeInput"
      v-model="selectedPlace"
      @input="getPredictions"
      @clear="predictions = []"
      clearable
    />

    <el-card v-if="predictions.length" class="predictions">

      <ul>
        <li 
          v-for="item in predictions"
          :key="item.place_id"
          @click="getLatLong(item)"
        >
          {{ item.description }}
        </li>
      </ul>
    </el-card>

    <div v-if="selectedPlace && location">

      <GmapMap :options="mapOptions"
        ref="map"
        class="mini_map"
        :center="this.location"
        :zoom="10"
        map-type-id="terrain"  
      >
        <GmapMarker
          :position="this.location"
          :icon="{ url: require('@/assets/marker.png'), scaledSize: { width: 35, height: 50, f: 'px', b: 'px'}}"
        />
      </GmapMap>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  axios.defaults.baseURL = 'https://admin.thevirtualquilt.com/api'

  /* VUE2-GOOGLE-MAPS
  ** https://www.npmjs.com/package/vue2-google-maps
  ** https://cloud.google.com/maps-platform/
  */
  import * as VueGoogleMaps from "vue2-google-maps"
  import mapStyles from '@/assets/mapStyles.json'

  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLEMAPS_API_KEY,
      libraries: 'places'
    }
  });

  export default {
    data() {
      return {
        loading: false,
        selectedPlace: null,
        place: null,
        location: null,
        predictions: [],

        mapOptions: {
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: true,
          draggable: false,
          styles: mapStyles
        },
      }
    },

    methods: {
 
      clearInput() {
        this.$refs.placeInput.clear();
      },

      getPredictions(query) {
        
        if(query) {
          // let url = "http://admin.thevirtualquilt.com/api/mapPredictions"
          // axios.get(`${url}/${query}/place`)
          axios.get(`/mapPredictions/${query}/place`)
          .then((response) => {
            this.predictions = response.data.predictions
          }, (err) => {
            console.log(err)
          })
        }

      },

      getLatLong(place) {
       
        this.selectedPlace = place.description
        this.predictions = []

        // let url = "http://admin.thevirtualquilt.com/api/mapPredictions"
        // axios.get(`${url}/${place.place_id}`)
        axios.get(`/mapPredictions/${place.place_id}`)
        .then((response) => {
          this.location = response.data.results[0].geometry.location
          this.$emit('location', { location:this.location, place:this.selectedPlace })
        }, (err) => {
            console.log(err)
        })

      }
    }
  }
</script>