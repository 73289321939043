export default {

    setLoading: (state, status) => {
        state.loading = status
    },

    openFormModal: (state) => {
        state.formModal = true
    },
    closeFormModal: (state) => {
        state.formModal = false
    },

    openViewModal: (state) => {
        state.viewModal = true
    },

    closeViewModal: (state) => {
        state.viewModal = false
    },

    openListModal: (state) => {
        state.listModal = true
    },

    closeListModal: (state) => {
        state.listModal = false
    },

    openHowToModal: (state) => {
        state.howToModal = true
    },

    closeHowToModal: (state) => {
        state.howToModal = false
    },

    setFeed: (state, feed) => {
        state.feed = feed
    },

    setTotalArtwork: (state, total) => {
        state.totalArtwork = total
    },

    setTotalPages: (state, total) => {
        state.totalPages = total
    },

    setAssets: (state, assets) => {
        state.assets = [...state.assets, ...assets]
    },

    setPoints: (state, points) => {
        state.points = points
    },

    setAsset: (state, asset) => {
        state.viewAsset = asset
    },

    pushAsset: (state, payload) => {
        state.assets.push({ ...payload });
    },

    setFileFilters: (state, payload) => {
        state.mapFilters.filetype = payload;
    },

    setCharityFilters: (state, payload) => {
        state.mapFilters.charity = payload;
    },

    setMoodFilters: (state, payload) => {
        state.mapFilters.mood = payload;
    },

    setRatingFilters: (state, payload) => {
        console.log(payload)
        state.mapFilters.rating = payload;
    },

    setModalImages: (state, payload) => {
        state.modalImages = payload;
    },

    setModalTotalImages: (state, payload) => {
        state.modalTotalImages = payload;
    },
    setCharities: (state, charities) => {
        const charityArray = [];
        charities.forEach((value) => {
            value.text = value.name;
            charityArray.push(value);
        });

        charityArray.sort((a, b) => {
            let fa = a.name.toLowerCase(), fb = b.name.toLowerCase();
            if (fa < fb) {
                return -1
            }
            if (fa > fb) {
                return 1
            }
            return 0
        })
        state.charities = charityArray

    },
}