<template>
  <el-dialog
    v-loading="loading"
    :visible="formModal"
    :before-close="closeModal"
    :close-on-click-modal="false"
    width="80%"
    class="modal"
  >
    <div class="logo-small">
      <MainLogo class="logo" />
    </div>

    <el-form
      class="form"
      :model="assetForm"
      :rules="rules"
      ref="assetForm"
      label-position="top"
    >
      <el-row :gutter="50">
        <el-col :md="12" :sm="24">
          <h2 class="header2">Your artwork</h2>

          <div v-if="assetForm.filetype == 'image'">
            <div class="placeholder" v-if="!assetForm.image_blob">
              <clipper-upload v-model="imgURL" v-if="!imgURL">
                <el-button type="primary">Upload Image</el-button>
              </clipper-upload>

              <clipper-basic
                :ratio="1"
                :grid="false"
                ref="clipper"
                class="clipper"
                :rotate="rotation"
                :src="imgURL"
                v-if="imgURL && !assetForm.image_blob"
              >
              </clipper-basic>
            </div>

            <el-row
              class="rotation-slider"
              v-if="imgURL && !assetForm.image_blob"
            >
              <el-col :md="6">
                <h4>Rotate Image</h4>
              </el-col>
              <el-col :md="18">
                <el-slider
                  v-model="rotation"
                  :marks="marks"
                  :min="-360"
                  :max="360"
                ></el-slider>
              </el-col>
            </el-row>

            <div class="placeholder" v-if="assetForm.image_blob">
              <img class="img-responsive result" :src="assetForm.image_blob" />
            </div>

            <el-row>
              <el-col :md="24">
                <el-button
                  type="primary"
                  @click="generateImage"
                  v-if="imgURL && !assetForm.image_blob"
                  >Crop</el-button
                >
                <el-button
                  type="default"
                  @click="replaceImage"
                  v-if="imgURL && !assetForm.image_blob"
                  >Replace Image</el-button
                >
                <el-button
                  type="default"
                  @click="resetImage"
                  v-if="imgURL && assetForm.image_blob"
                  >Reset Image</el-button
                >
              </el-col>
            </el-row>
          </div>

          <div v-else>
            <div class="placeholder" slot="placeholder">
              <div>
                I am sharing an <br /><br />
                <el-button type="primary" @click="assetType('image')"
                  ><imageIcon /> Image</el-button
                >
              </div>
            </div>
          </div>
        </el-col>

        <el-col :md="12" :sm="24">
          <div class="details">
            <h2 class="header2">Your details</h2>

            <!-- Form fields -->

            <el-form-item label="Name" prop="username">
              <el-input
                v-model="assetForm.username"
                placeholder="Insert your name"
              ></el-input>
            </el-form-item>

            <el-form-item label="Email" prop="email">
              <el-input
                v-model="assetForm.email"
                placeholder="Insert your email"
              ></el-input>
            </el-form-item>

            <small
              >Your email will only be used to notify you when your image has
              been approved for viewing. We will not share or spam your
              email.</small
            >
            <br /><br /><br />

            <el-form-item>
              <el-checkbox name="subscribed" v-model="assetForm.subscribed">
                <strong>Interested in nurturing creativity? </strong><br />
                <small
                  >Opt in to receive our newsletter called The
                  Groovalution</small
                >
              </el-checkbox>
            </el-form-item>

            <el-form-item label="What is the story behind this?" prop="story">
              <el-input
                type="textarea"
                v-model="assetForm.story"
                placeholder="Tell us a story"
                rows="4"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="Where was this taken?" prop="place">
              <CityTypeahead ref="locationInput" v-on:location="setLocation" />
            </el-form-item>

            <el-form-item class="form-charity-dropdown" label="Non Profits" prop="charity">
                <multiselect v-model="assetForm.charity" deselect-label="Remove" track-by="id" label="name" placeholder="Select Non Profit" :options="charities" :searchable="true" :allow-empty="true">
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                </multiselect>
            </el-form-item>

            <el-form-item
              label="How does it make you feel?"
              prop="mood"
              class="mood"
            >
              <el-radio-group v-model="assetForm.mood">
                <el-radio v-for="mood in mood" :label="mood" :key="mood">
                  <el-tooltip effect="dark" :content="mood" placement="top">
                    <component :is="mood" />
                  </el-tooltip>
                </el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm('assetForm')"
                :disabled="!assetForm.image_blob && !assetForm.video_file"
                >Submit</el-button
              >
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import MainLogo from "@/assets/Logo.svg";

import CityTypeahead from "@/components/CityTypeahead";

import videoIcon from "@/assets/filter/video.svg";
import imageIcon from "@/assets/filter/image.svg";

import excited from "@/assets/mood/excited.svg";
import happy from "@/assets/mood/happy.svg";
import surprised from "@/assets/mood/surprised.svg";
import bored from "@/assets/mood/bored.svg";
import sad from "@/assets/mood/sad.svg";
import angry from "@/assets/mood/angry.svg";

import Multiselect from 'vue-multiselect'



export default {
  components: {
    MainLogo,
    CityTypeahead,
    videoIcon,
    imageIcon,
    excited,
    happy,
    surprised,
    bored,
    sad,
    angry,
    Multiselect
  },

  data() {
    return {
      imgURL: null,

      rotation: 0,
      marks: { "-360": "-360°", 0: "0°", 360: "360°" },

      assetForm: {
        username: "",
        email: "",
        subscribed: false,
        story: "",
        mood: "",
        place: "",
        lat: null,
        lng: null,
        filetype: null,
        image_blob: null,
        video_file: null,
        charity_id: null
      },

      rules: {
        username: [
          {
            required: true,
            message: "Please insert your name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please insert email address",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please input a correct email address",
            trigger: ["blur", "change"],
          },
        ],
        story: [
          { required: true, message: "Please enter a story", trigger: "blur" },
        ],
        place: [
          { required: true, message: "Please enter a place", trigger: "blur" },
        ],
      },
    };
  },
  mounted () {
      this.getAllCharities();

    },

  computed: {
    ...mapState(["loading", "formModal", "mood", "charities"]),
  },

  methods: {
    ...mapMutations(["setLoading", "closeFormModal", "setCharities"]),
    ...mapActions(["createAsset", "fetchCharities"]),

    getAllCharities: function() {
        this.fetchCharities(this.page)
        .then(() => {
            this.setCharities(this.charities)
            this.setLoading(false)
        });
    },
    closeModal: function () {
      this.imgURL = null;
      (this.assetForm = {
        username: "",
        email: "",
        subscribed: false,
        story: "",
        mood: "",
        place: "",
        lat: null,
        lng: null,
        filetype: null,
        image_blob: null,
        video_file: null,
        charity_id: null,
      }),
        this.closeFormModal();
      this.$refs.locationInput.clearInput();

      if (this.$route.name == "startsharing") {
        this.$router.push({ path: `/quilt` });
      }
    },

    assetType: function (type) {
      this.assetForm.filetype = type;
    },

    generateImage: function () {
      const canvas = this.$refs.clipper.clip({ wPixel: 800 }); // call component's clip method
      this.assetForm.image_blob = canvas.toDataURL("image/jpeg", 1); // canvas->image
    },

    uploadVideo: function () {
      this.assetForm.video_file = this.$refs.upload.files[0];
    },

    removeVideo: function () {
      this.assetForm.video_file = null;
    },

    replaceImage: function () {
      this.imgURL = null;
    },

    resetImage: function () {
      this.assetForm.image_blob = null;
    },

    setLocation: function (location) {
      this.assetForm.place = location.place;
      this.assetForm.lat = location.location.lat;
      this.assetForm.lng = location.location.lng;
      this.$refs.assetForm.clearValidate(["place"]);
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.setLoading(true);
          if(this.assetForm.charity != null && this.assetForm.charity != "") {
            if(Object.keys(this.assetForm.charity).length > 0) {
                this.assetForm.charity_id = this.assetForm.charity.id;
            }
          }
          console.log(this.assetForm);
          this.createAsset(this.assetForm)
            .then((response) => {
              console.log(response);
              this.setLoading(false);

              this.$notify({
                title: "Success",
                message:
                  "File uploaded with success. Your content is now being reviewed.",
                type: "success",
              });

              this.closeModal();
            })
            .catch((error) => {
              console.log(error);
              this.setLoading(false);

              this.$notify({
                title: "Error",
                message:
                  "An error occured while uploading. Please check our file size limit and try again later.",
                type: "error",
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    // },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.form-charity-dropdown .el-form-item__content{
    line-height: 20px;
}
</style>