<template>

  <div>

    <el-dialog
      :visible.sync="termsModal"
      width="50%"
      class="modal"
    >
      <div class="logo-small">
        <MainLogo class="logo" />
      </div>

      <br/><br/>  
      <h2 class="header2">Terms of Use</h2>
      <br/><br/>  
      
      <p>The content added to the Virtual Quilt platform represents the views and opinions of its contributors. It does not necessarily represent the views or opinions of The Virtual Quilt creators. The appearance of content on the site does not constitute an endorsement by the Virtual Quilt or its partners.</p><br/><br/>
      <p>The platform is available for informational and educational purposes. The Virtual Quilt hereby disclaims any liability to any party for direct, indirect, implied, punitive, special, incidental or other consequential damages arising directly or indirectly from any use of the Virtual Quilt, which is provided as is, without warranties.</p><br/><br/>
      <p>The opinions expressed and content shared on the platform may not reflect the views of the Virtual Quilt but respect the creativity of the users. The Virtual Quilt is administered by the nonprofit organization, the Give into the Grove Foundation, at the Bluegrass Community Foundation.</p><br/><br/>
      <p>Regards,<br/> The Virtual Quilt Team</p>
      
    </el-dialog>

    <el-button type="default" size="mini" class="terms" @click="termsModal = true">Terms of Use</el-button>
  </div>
</template>

<style scoped>
  .terms {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
  }

  .modal {
    padding-top: 2%;
  }
</style>

<script>
import MainLogo from "@/assets/Logo.svg";

export default {
  components: {
    MainLogo
  },

  data() {
    return {
      termsModal: false
    }
  },
};
</script>
