import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { router } from '@/router'
import { store } from '@/store'

// STYLES
import '@/styles/styles.less'

/* ELEMENT UI
** https://element.eleme.io
*/
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import locale from 'element-ui/lib/locale/lang/en'

/* INFINITE GRID
** https://naver.github.io/egjs-infinitegrid/release/latest/doc/
*/
import VueInfiniteGrid from "@egjs/vue-infinitegrid";

/* vuejs-clipper
** https://www.npmjs.com/package/vuejs-clipper
*/
import VueRx from 'vue-rx'
import VuejsClipper from 'vuejs-clipper'
import "vuejs-clipper/dist/vuejs-clipper.css";

import VueMq from 'vue-mq'

import * as dayjs from 'dayjs'

Vue.filter('formatDate', function(value, format) {
  if (value) {
    return dayjs(String(value)).format(format)
  }
})

Vue.use(VueRx)
Vue.use(VuejsClipper, {
  components: {
    clipperBasic: true,
    clipperFixed: true,
    clipperUpload: true,
    clipperPreview: true
  }
})

Vue.use(ElementUI, { locale })
Vue.use(VueInfiniteGrid);
Vue.config.productionTip = false

Vue.use(VueMq, {
  breakpoints: {
    mobile: 768,
    tablet: 992,
    laptop: 1250,
    desktop: Infinity,
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
