<template>
  <div id="app">
    <Navigation />
    <FormModal />
    <TermsModal />
    <router-view />
  </div>
</template>

<script>
  import Navigation from '@/components/Navigation'
  import FormModal from '@/components/FormModal'
  import TermsModal from '@/components/TermsModal'

  export default {
    components: {
      Navigation,
      FormModal,
      TermsModal
    }
  }
</script>
