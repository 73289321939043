<template>
  <div class="navigation">

    <el-row>

      <el-col :md="2" :sm="2" :xs="4">
        <router-link to="/">
          <Icon class="icon" />
        </router-link>
      </el-col>
        
      <el-col :md="22" :sm="22" :xs="20">
        
        <div class="links">

          <!-- Playlist iFrame -->   
          <div class="spotify-widget" v-show="playlist_visible">
            <iframe :src="playlist_url" width="300" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
          </div>

          <!-- MOBILE NAVIGATION -->  
          <div class="hidden-md-and-up">

              <el-dropdown @visible-change="togglePlaylistPopup" @command="loadPlaylist" placement="bottom" >

                  <el-button type="default">
                    <i class="el-icon-headset"></i>
                  </el-button>
                
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item 
                    v-for="(playlist,index) in playlists"
                    :key="index"
                    :command="playlist"
                  >
                    {{ playlist.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>

              </el-dropdown>

              <el-button class="playlist-bt" @click="togglePlaylist">
                <i v-if="playlist_visible == false" class="el-icon-view"></i> 
                <i v-else class="el-icon-close"></i> 
              </el-button>


            <el-dropdown>
              <span class="el-dropdown-link">
                <Bars class="mobile-icon"/>
              </span>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><router-link to="/quilt">The Virtual Quilt</router-link></el-dropdown-item>
                <el-dropdown-item><router-link to="/map">Explore Map</router-link></el-dropdown-item>
                <el-dropdown-item><router-link to="/groovalutionaryu">GroovalutionaryU</router-link></el-dropdown-item>
                <el-dropdown-item><router-link to="/doodlefordollars">Doodle for dollars</router-link></el-dropdown-item>
                <el-dropdown-item>
                  <el-button type="primary" @click="openFormModal">Start Sharing</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>


          <!-- DESKTOP NAVIGATION -->
          <div class="hidden-sm-and-down">

              <router-link to="/quilt">The Virtual Quilt</router-link> |
              <router-link to="/map">Explore Map</router-link> | 
              <router-link to="/groovalutionaryu">GroovalutionaryU</router-link> |
              <router-link to="/doodlefordollars">Doodle for dollars</router-link>



              <el-dropdown @visible-change="togglePlaylistPopup" @command="loadPlaylist" placement="bottom" >

                  <el-button type="default">
                    <i class="el-icon-headset"></i> Set The Mood
                  </el-button>
                
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item 
                    v-for="(playlist,index) in playlists"
                    :key="index"
                    :command="playlist"
                  >
                    {{ playlist.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>

              </el-dropdown>

              <el-button class="playlist-bt" @click="togglePlaylist">
                <i v-if="playlist_visible == false" class="el-icon-view"></i> 
                <i v-else class="el-icon-close"></i> 
              </el-button>

              <el-button type="primary" @click="openFormModal">Start Sharing</el-button>

          </div>
        </div>

      </el-col>

      
    </el-row>
        
  </div>
</template>

<script>
import { mapMutations } from "vuex"
import Icon from '@/assets/Icon.svg'
import Bars from '@/assets/Bars.svg'

export default {
  components: {
    Icon,
    Bars
  },
  data() {
    return {
      playlist_visible: false,
      playlist_url: 'https://open.spotify.com/embed/playlist/2xPeo9xvbBJjdIcRptLuM8?si=5345471ea3184d0e&nd=1',
      playlists: [
        { name:"Cuidadano Del Mundo", link:"https://open.spotify.com/embed/playlist/2xPeo9xvbBJjdIcRptLuM8?si=5345471ea3184d0e&nd=1" },
        { name:"Creative Mindset", link:"https://open.spotify.com/embed/playlist/3OaT4Rdpt4CCJrMI6qQSG9?si=b1bfd29592ee4c7b&nd=1" },
        { name:"California Vibes", link:"https://open.spotify.com/embed/playlist/0234mnpLmsv9BpFMcNgzY7?si=7e7280f798a449ed&nd=1" }
      ]
    }
  },
  methods: {
    ...mapMutations([
      'openFormModal'
    ]),

    togglePlaylistPopup: function(visible) {
      this.playlist_visible = !visible
    },

    togglePlaylist: function() {
      this.playlist_visible = !this.playlist_visible
    },

    loadPlaylist: function (playlist) {
      this.playlist_visible = true;
      this.playlist_url = playlist.link
    }
  },
}
</script>