export default {

    loading: false,
    formModal: false,
    viewModal: false,
    listModal: false,
    howToModal: false,
    totalPages: 1,
    totalArtwork: 0,
    feed: [],
    assets: [],
    points: [],
    charities: [],

    modalImages: [],
    modalTotalImages: [],

    mapFilters: {
        filetype: [],
        charity: [],
        mood: [],
        rating: []
    },

    mood: [
        "excited",
        "happy",
        "surprised",
        "bored",
        "sad",
        "angry"
    ],

    viewAsset: {}

}