import axios from "axios";

const baseURL = `${process.env.VUE_APP_API_URL}`;
// const Json = axios.create({ baseURL: '/data' })
const API = axios.create({ baseURL });

export default {
    fetchAssets: async ({ commit }, page) => {
        return new Promise((resolve, reject) => {
            API.get(`/artwork?per_page=48&page=${page}`)
                .then((response) => {
                    const feed = response.data.data.shuffled();
                    const totalPages = response.data.meta.last_page;
                    commit("setFeed", feed);
                    commit("setTotalPages", totalPages);
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });
        });
    },

    fetchPoints: async ({ state, commit }) => {
        commit("setLoading", true);

        return new Promise((resolve, reject) => {
            let filters = "";

            if (
                state.mapFilters.filetype.length ||
                state.mapFilters.mood.length ||
                state.mapFilters.rating.length ||
                state.mapFilters.charity.length
            ) {
                filters += "?";
                if (state.mapFilters.filetype.includes("image")) filters += "image=1&";
                if (state.mapFilters.filetype.includes("video")) filters += "video=1&";
                if (state.mapFilters.charity.length)
                    filters += `charity=${state.mapFilters.charity}&`;
                if (state.mapFilters.mood.length)
                    filters += `mood=${state.mapFilters.mood}&`;
                if (state.mapFilters.rating.length)
                    filters += `stars=${state.mapFilters.rating}&`;

            }

            API.get(`/get-map${filters}`)
                .then((response) => {
                    const feed = response.data.points;
                    const total = response.data.totalCount;
                    commit("setPoints", feed);
                    commit("setTotalArtwork", total);
                    commit("setLoading", false);
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });
        });
    },

    fetchLocationArtwork: async ({ state, commit }, payload) => {
        return new Promise((resolve, reject) => {
            let filters = "&";

            if (state.mapFilters.filetype.length || state.mapFilters.mood.length) {
                if (state.mapFilters.filetype.includes("image")) filters += "image=1&";
                if (state.mapFilters.filetype.includes("video")) filters += "video=1&";
                if (state.mapFilters.mood.length)
                    filters += `mood=${state.mapFilters.mood}`;
            }

            let currentPage = payload.page;
            let lat = `&lat=${payload.lat}`;
            let lng = `&lng=${payload.lng}`;

            API.get(
                `/image-lat-lng?per_page=12${lat}${lng}&page=${currentPage}${filters}`
            )
                .then((response) => {
                    const images = response.data.data;
                    const total = response.data.meta.total;
                    commit("setModalImages", images);
                    commit("setModalTotalImages", total);
                    commit("setLoading", false);
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });
        });
    },

    getAssetDetails: ({ commit }, id) => {
        commit("setLoading", true);

        return new Promise((resolve, reject) => {
            API.get(`/artwork/${id}`)
                .then((response) => {
                    const asset = response.data.data;
                    commit("setAsset", asset);
                    commit("setLoading", false);
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });
        });
    },

    rateAsset: ({ commit }, payload) => {
        commit("setLoading", true);

        let formData = {
            id: payload.id,
            rating: payload.rating,
        };

        API.defaults.headers.common = {
            Accept: "application/json",
        };

        return new Promise((resolve, reject) => {
            API.post("/rate-image", formData)
                .then(() => {
                    commit("setLoading", false);
                    resolve();
                })
                .catch((error) => {
                    commit("setLoading", false);
                    reject(error);
                });
        });
    },

    createAsset: ({ commit }, payload) => {
        commit("setLoading", true);

        let formData;

        if (payload.filetype == "image") {
            // console.log("Setting Image Headers...");

            delete payload.video_file;

            formData = payload;

            API.defaults.headers.common = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };
        }

        if (payload.filetype == "video") {
            console.log("Setting Video Defaults");

            formData = new FormData();

            formData.append("username", payload.username);
            formData.append("email", payload.email);
            formData.append("subscribed", payload.subscribed);
            formData.append("story", payload.story);
            formData.append("mood", payload.mood);
            formData.append("place", payload.place);
            formData.append("lat", payload.lat);
            formData.append("lng", payload.lng);
            formData.append("filetype", payload.filetype);
            formData.append("image_blob", payload.image_blob);
            formData.append("video_file", payload.video_file);
            formData.append("charity_id", payload.charity_id);

            API.defaults.headers.common = {
                Accept: "application/json",
                "Accept-Encoding": "multipart/form-data",
                "Content-Type": "multipart/form-data",
            };
        }

        return new Promise((resolve, reject) => {
            API.post("/artwork/create", formData)
                .then((response) => {
                    console.log(response);
                    commit("setLoading", false);
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    commit("setLoading", false);
                    reject(error);
                });
        });
    },

    fetchCharities: async ({ commit }, page) => {
        return new Promise((resolve, reject) => {
            API.get(`/charity?per_page=48&page=${page}`)
                .then((response) => {
                    const charities = response.data.data.shuffled();
                    const totalPages = response.data.meta.last_page;
                    commit("setCharities", charities);
                    commit("setTotalPages", totalPages);
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });
        });
    },
};
